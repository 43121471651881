import { template as template_a43db66eee8b4c11a0c31df76275fcc4 } from "@ember/template-compiler";
import icon from "discourse-common/helpers/d-icon";
const InputTip = template_a43db66eee8b4c11a0c31df76275fcc4(`
  <div
    class="tip {{if @validation.failed 'bad' 'good'}}"
    id={{@id}}
    ...attributes
  >
    {{#if @validation.reason}}
      {{icon (if @validation.failed "xmark" "check")}}
      {{@validation.reason}}
    {{/if}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default InputTip;
