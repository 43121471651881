import { template as template_6e0a1d1a94b64a7c9f36997c24262b7f } from "@ember/template-compiler";
const FKText = template_6e0a1d1a94b64a7c9f36997c24262b7f(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
