import { template as template_9e5e61021b4d46d1b877d54013226632 } from "@ember/template-compiler";
const WelcomeHeader = template_9e5e61021b4d46d1b877d54013226632(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
