import { template as template_159e98200de5479487d83259a8370791 } from "@ember/template-compiler";
const FKControlMenuContainer = template_159e98200de5479487d83259a8370791(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
